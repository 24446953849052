import React, { useState, useEffect } from "react"
import { Helmet } from "react-helmet"
import useSiteMetadata from '../hooks/use-site-metadata';

import TopBar from "./top-bar.js"
import Footer from "./footer.js"

const Layout = ({ location, children }) => { 
  const siteData=useSiteMetadata();

  const [navOpen, setNavOpen] = useState(false);

  // On window resize close the nav menu. This fixes a weird problem when resizing with nav menu open from md to mobile.
  useEffect(() => {
    const handleResize = () => setNavOpen(false);

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, [])

  var bodyClasses="text-reset bg-orange-100 md:overflow-auto";
  if (navOpen) {
    bodyClasses += " overflow-hidden"
  } else {
    bodyClasses += " overflow-auto"
  }

  return (
    <>
      <Helmet>
        <body className={bodyClasses} />
      </Helmet>

      <TopBar location={location} siteMetadata={siteData.site.siteMetadata} logoImg={siteData.logo.childImageSharp} navOpen={navOpen} setNavOpen={setNavOpen} />

      <main className="mt-12 md:mt-20">{children}</main>

      <Footer siteMetadata={siteData.site.siteMetadata} cartoonImg={siteData.cartoon.childImageSharp} navOpen={navOpen} setNavOpen={setNavOpen} />
    </>
  )
}

export default Layout
