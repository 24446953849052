import React from "react"
import Iconify from "./iconify.js"

const BlockQuote = ({ className, children, quote, footer, cite }) => {
  return (
    <>
      <blockquote className={className}>
        {children}

        {quote && (
          <>
            <div className="flex items-center w-full">
              <Iconify name="formatQuoteClose" className="text-lg md:text-2xl" hFlip />
              <div className="flex-1 ml-2 border-t border-dotted"></div>
            </div>

            <h2 className="mb-1 font-thin sm:text-lg md:text-2xl">{quote}</h2>
            <div className="flex items-center w-full">
              <div className="flex-1 mr-2 border-t border-dotted"></div>
              {footer && (
                <>
                  <div className="text-xs">{footer}</div>
                  {cite && ", " + cite}
                </>
              )}
              <div className="flex-1 mx-2 border-t border-dotted"></div>
              <Iconify name="formatQuoteClose" className="text-lg md:text-2xl" />
            </div>

          </>
        )}
      </blockquote>
    </>
  )
}

export default BlockQuote
