import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

import NavButton from "./elements/nav-button.js"
import NavMenu from "./elements/nav-menu.js"

const TopBar = ({ location, siteMetadata, logoImg, navOpen, setNavOpen }) => {
  return (
    <header className="fixed z-30 text-fluid sm:text-base md:h-20 md:overflow-hidden md:flex md:items-center md:justify-between top-0 inset-x-0 bg-blue-900 text-gray-300">
      <div className="h-12 px-4 flex items-center justify-between">
        <Link to="/" className="flex items-center">
          <Img fixed={logoImg.fixed} alt={siteMetadata.title + " logo"} />
          <span className="ml-4 text-xl font-light">{siteMetadata.title}</span>
        </Link>
        <NavButton navOpen={navOpen} setNavOpen={setNavOpen} />
      </div>
      <NavMenu
        location={location}
        menuLinks={siteMetadata.menuLinks}
        navOpen={navOpen}
        setNavOpen={setNavOpen}
      />
    </header>
  )
}

export default TopBar
