import React from "react"
import { Link } from "gatsby"
import Iconify from "./iconify.js"

const NavMenu = ({ location, menuLinks, navOpen, setNavOpen }) => {
  var navClasses =
    "fixed top-0 mt-12 inset-x-0 bg-black-tr-90 transition-all transform duration-500 md:static md:translate-x-0 md:bg-transparent md:mt-0 md:px-4 md:h-auto"
  if (navOpen) {
    navClasses += " translate-x-0"
  } else {
    navClasses += " translate-x-full"
  }

  return (
    <nav className={navClasses}>
      <ul className="grid grid-cols-3">
        {menuLinks.map(link => (
          <li
            key={link.name}
            className="border-l border-b last:border-r border-gray-500 border-dotted md:border-b-0"
          >
            {(() => {
              if (location.pathname === link.link) {
                return (
                  <div
                    className={
                      "flex flex-col items-center px-4 py-8 box-border border-b-4 " +
                      link.borderColorClass +
                      " bg-gray-800 md:pt-3 md:pb-2"
                    }
                  >
                    <Iconify
                      name={link.icon}
                      className={"w-8 h-8 " + link.textColorClass}
                    />
                    <h1>{link.name}</h1>
                  </div>
                )
              } else {
                return (
                  <Link
                    to={link.link}
                    className="group flex flex-col items-center box-border px-4 py-8 hover:bg-gray-800 md:py-3"
                  >
                    <Iconify
                      name={link.icon}
                      className={"w-8 h-8 " + link.hoverTextColorClass}
                    />
                    <div>{link.name}</div>
                  </Link>
                )
              }
            })()}
          </li>
        ))}
      </ul>
    </nav>
  )
}

export default NavMenu
