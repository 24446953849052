import React from "react"
import Img from "gatsby-image"

const HeaderImage = ({ children, image }) => {

  var imageHtml="";
  if (image) {
    imageHtml=<Img fluid={image} alt="Header BG" className="w-full h-full" />
  }else{
    imageHtml=<div className="w-full h-full bg-gradient"/>
  }


  return (
    <>
      <header className="relative h-1/2h overflow-hidden text-fluid md:text-base">
        <div className="absolute inset-0 z-10 bg-black-tr-40 text-gray-300">
          {children}
        </div>
        {imageHtml}
      </header>
    </>
  )
}

export default HeaderImage
