import React from "react"

const Row = ({ xFull, hFull, noSeparated, classNames, children }) => {
  var rowClasses = ""
  if (xFull !== true) {
    rowClasses += " max-w-screen-xl mx-4 px-4 xl:mx-auto"
  }
  if (hFull !== true) {
    rowClasses += " py-10"
  }
  if(noSeparated !== true){
    rowClasses+=" border-b-2 border-dotted border-orange-200 last:border-0"
  }
  if (classNames) {
    rowClasses += " " + classNames
  }

  return (
    <>
      <section className={rowClasses}>{children}</section>
    </>
  )
}

export default Row
