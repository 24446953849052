import React from "react"
import { Helmet } from "react-helmet"
import useSiteMetadata from "../hooks/use-site-metadata"

const SEO = ({ title, description, image, type, location }) => {
  const siteData = useSiteMetadata().site.siteMetadata

  const seo = {
    title: title || siteData.siteTitle,
    description: description || siteData.description,
    image: siteData.siteUrl + "/" + (image || siteData.image),
    siteUrl: siteData.siteUrl + location.pathname,
    type: type || "website",
  }

  return (
    <>
      <Helmet title={seo.title} titleTemplate={siteData.titleTemplate}>
        <html lang="en" />
        <meta name="description" content={seo.description} />
        <meta name="image" content={seo.image} />

        <meta property="og:title" content={seo.title} />
        <meta property="og:url" content={seo.siteUrl} />
        <meta property="og:type" content={seo.type} />
        <meta property="og:description" content={seo.description} />
        <meta property="og:image" content={seo.image} />

        <meta name="twitter:card" content="summary_large_image" />
        {siteData.twitterUsername && (
          <meta name="twitter:creator" content={siteData.twitterUsername} />
        )}
        <meta name="twitter:title" content={seo.title} />
        <meta name="twitter:description" content={seo.description} />
        <meta name="twitter:image" content={seo.image} />
        
        <link rel="canonical" href={seo.siteUrl} />
      </Helmet>
    </>
  )
}
export default SEO
