import React from "react"

import { Icon } from "@iconify/react"
import windowClose from '@iconify/icons-mdi/window-close';
import menuIcon from '@iconify/icons-mdi/menu';

import homeOutline from '@iconify/icons-mdi/home-outline';
import cardAccountDetailsOutline from '@iconify/icons-mdi/card-account-details-outline';
import atomIcon from '@iconify/icons-mdi/atom';
import formatQuoteClose from '@iconify/icons-mdi/format-quote-close';

import emailOpenOutline from '@iconify/icons-mdi/email-open-outline';
import facebookF from '@iconify/icons-cib/facebook-f';
import instagramIcon from '@iconify/icons-cib/instagram';
import linkedinIn from '@iconify/icons-cib/linkedin-in';

import cartOutline from '@iconify/icons-mdi/cart-outline';
import codeJson from '@iconify/icons-mdi/code-json';
import speedometerIcon from '@iconify/icons-mdi/speedometer';
import trendingUp from '@iconify/icons-mdi/trending-up';
import accountOutline from '@iconify/icons-mdi/account-outline';
import babyCarriage from '@iconify/icons-mdi/baby-carriage';
import mapMarkerRadiusOutline from '@iconify/icons-mdi/map-marker-radius-outline';
import tennisIcon from '@iconify/icons-mdi/tennis';
import volleyballIcon from '@iconify/icons-mdi/volleyball';
import ninjaIcon from '@iconify/icons-mdi/ninja';


// name - string indicating the name of the icon
// className - string defining the css classes for the svg
// width, height - width and/or height of icon. If one attribute is set, other attribute will be calculated using width/height ratio of icon. Default value is "1em".
// hFlip, vFlip - boolean attributes. You can use them to flip icon vertically or horizontally
// rotate - rotate icon. Value is number 0-3 (1 = 90deg, 2 = 180deg, 3 = 270deg) or string "90deg", "180deg", "270deg"
// color - icon color, usable only for colorless icons. By default colorless icons use currentColor, so you can set color using stylesheet by setting text color. This property can override it.
const Iconify = ({
  name,
  className,
  width,
  height,
  hFlip,
  vFlip,
  rotate,
  color,
}) => {
  var icons = {
    windowClose: windowClose,
    menuIcon: menuIcon,
    homeOutline: homeOutline,
    cardAccountDetailsOutline: cardAccountDetailsOutline,
    atomIcon: atomIcon,
    formatQuoteClose: formatQuoteClose,

    emailOpenOutline: emailOpenOutline,
    facebookF: facebookF,
    instagramIcon: instagramIcon,
    linkedinIn: linkedinIn,

    cartOutline: cartOutline,
    codeJson: codeJson,
    speedometerIcon: speedometerIcon,
    trendingUp: trendingUp,
    accountOutline: accountOutline,
    babyCarriage: babyCarriage,
    mapMarkerRadiusOutline: mapMarkerRadiusOutline,
    tennisIcon: tennisIcon,
    volleyballIcon: volleyballIcon,
    ninjaIcon: ninjaIcon,
  }

  return (
    <Icon
      icon={icons[name]}
      className={className}
      width={width}
      height={height}
      hFlip={hFlip}
      vFlip={vFlip}
      rotate={rotate}
      color={color}
    />
  )
}

export default Iconify