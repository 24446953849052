import { useStaticQuery, graphql } from "gatsby"

const useSiteMetadata = () => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
          titleTemplate
          description
          siteUrl
          image
          twitterUsername
          menuLinks {
            link
            name
            icon
            textColorClass
            hoverTextColorClass
            borderColorClass
          }
          socialLinks {
            type
            title
            icon
            url
            prefixText
            bgColorClass
            textColorClass
            hoverTextColorClass
          }
        }
      }
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fixed(height: 32) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      cartoon: file(relativePath: { eq: "cartoon.png" }) {
        childImageSharp {
          fixed(height: 96) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return data
}

export default useSiteMetadata
