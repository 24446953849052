import React from "react"
import Img from "gatsby-image"
import Obfuscate from "react-obfuscate"

import Iconify from "./elements/iconify.js"

const Footer = ({ siteMetadata, cartoonImg, navOpen, setNavOpen }) => {
  var footerClasses =
    "fixed z-20 inset-y-0 inset-x-0 overflow-auto transition-all transform duration-500 p-4 bg-gray-900 text-gray-400 text-fluid text-center sm:flex sm:flex-wrap sm:items-center sm:justify-around sm:text-base md:static md:translate-x-0"
  if (navOpen) {
    footerClasses += " translate-x-0"
  } else {
    footerClasses += " translate-x-full"
  }

  return (
    <footer className={footerClasses}>
      <div className="mt-48 mb-4 pb-4 border-b border-gray-700 sm:w-1/2 sm:flex-none sm:mb-0 sm:pb-0 sm:pr-2 sm:border-0 md:mt-0">
        <Img fixed={cartoonImg.fixed} alt={siteMetadata.title + " avatar"} />
        <div className="text-3xl font-light">{siteMetadata.title}</div>
        <div dangerouslySetInnerHTML={{ __html: siteMetadata.description }} />
      </div>

      <div className="mb-4 pb-1 border-b border-gray-700 sm:w-1/2 sm:flex-none sm:mt-48 sm:mb-0 sm:pb-0 sm:pl-2 sm:border-0 md:mt-0">
        <ul className="inline-flex flex-col text-left">
          {siteMetadata.socialLinks.map(link => (
            <li key={link.title} className="mb-3">
              {(() => {
                const opt = { [link.type]: link.url }

                return (
                  <Obfuscate
                    {...opt}
                    title={link.prefixText + " " + link.title}
                    className="group flex items-center"
                    obfuscateChildren={false}
                  >
                    <div
                      className={
                        link.bgColorClass +" "+ link.hoverTextColorClass+
                        " inline-block rounded-full p-2 transition-colors duration-500 group-hover:bg-gray-400"
                      }
                    >
                      <Iconify name={link.icon} width="24" />
                    </div>

                    <div className="ml-3">
                      <div className="text-sm">{link.prefixText}</div>
                      <div
                        className={
                          link.textColorClass +
                          " text-lg font-semibold"
                        }
                      >
                        {link.title}
                      </div>
                    </div>
                  </Obfuscate>
                )
              })()}
            </li>
          ))}
        </ul>
      </div>

      <div className="border-gray-700 sm:w-full sm:mt-2 sm:pt-2 sm:border-t">
        Copyright © 2020 agazanis.com
        <br />
        All rights reserved
      </div>
    </footer>
  )
}

export default Footer
