import React from "react"

const HTitle = ({ children, Type, className }) => {
  var hClasses = ""
  switch (Type) {
    case "h2":
      hClasses += "text-3xl"
      break
    case "h3":
      hClasses += "text-2xl text-center text-red-600 font-semibold mb-6"
      break
    case "h4":
      hClasses += "text-xl"
      break
    case "h5":
      hClasses += "text-lg"
      break
    case "h6":
      break
    default:
      return null
  }
  if (className) {
    hClasses += " " + className
  }

  return (
    <>
      <Type className={hClasses}>{children}</Type>
    </>
  )
}

export default HTitle
