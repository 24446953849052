import React from "react"
import Iconify from "./iconify.js"

const NavButton = ({ navOpen, setNavOpen }) => {
  var buttonClasses = "md:hidden relative rounded-full transition-transform duration-500 focus:outline-none"
  var menuClasses = "h-8 w-8 p-1 fill-current transition-opacity duration-500"
  var closeClasses = menuClasses + " absolute inset-0"

  if (navOpen) {
    buttonClasses += " bg-red-800 transform rotate-90"
    menuClasses += " opacity-0"
    closeClasses += " opacity-100 translate-x-2"
  } else {
    menuClasses += " opacity-100 translate-x-2"
    closeClasses += " opacity-0"
  }

  return (
    <button
      type="button"
      className={buttonClasses}
      onClick={() => setNavOpen(!navOpen)}
    >
      <Iconify name="menuIcon" className={menuClasses} />
      <Iconify name="windowClose" className={closeClasses} />
    </button>
  )
}

export default NavButton
